import 'moment/locale/en-nz';
import * as moment from 'moment';

import {Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {
    BranchDetailsModel,
    BranchDetailsPaymentModel,
    CreateBookingModel,
    RoomModel,
    RoomTimeModel
} from './main.component.model';
import {HttpClientService} from '../../core/http-client/http-client.service';

import * as _ from 'lodash';
import {BookingService} from '../../services/booking/booking.service';
import {Observable, Subscription} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {GoogleAnalyticsService} from '../../services/google-analytics/google-analytics.service';
import {Angulartics2} from 'angulartics2';
import {Angulartics2GoogleGlobalSiteTag} from 'angulartics2/gst';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import {AcceptJSService, Config, CreditCard} from '@openutility/acceptjs-angular-wrapper';
import {Angulartics2Facebook} from 'angulartics2/facebook';
import {FacebookAnalyticsService} from '../../services/facebook-pixel/facebook-pixel.service';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import {DatepickerDateCustomClasses} from 'ngx-bootstrap/datepicker/models';
import { NgZone } from '@angular/core';
import {PaymentTypeEnum} from '../../enums/payment-type.enum';
import {
    StripeElementsOptions,
    StripeCardElementOptions,
    StripeElements,
    StripePaymentRequestButtonElement, PaymentRequestOptions
} from '@stripe/stripe-js';
import {StripeService} from "ngx-stripe";

@Component({
    selector: 'bp-main',
    templateUrl: './main.component.html'
})
export class MainComponent implements OnInit {
    private stripeLoaded = false;

    constructor(private _router: Router,
                private _activatedRoute: ActivatedRoute,
                private _http: HttpClientService,
                private _fb: FormBuilder,
                private _bookingService: BookingService,
                @Inject(DOCUMENT) private document,
                private _stripeService: StripeService,
                private _googleAnalyticsService: GoogleAnalyticsService,
                private _facebookAnalyticsService: FacebookAnalyticsService,
                private _angulartics2: Angulartics2,
                private _angularticsGoogle: Angulartics2GoogleGlobalSiteTag,
                private _angularticsGoogleAnalytics: Angulartics2GoogleAnalytics,
                private _angularticsFacebook: Angulartics2Facebook,
                private _acceptJSSrv: AcceptJSService,
                private _el: ElementRef,
                private _zone: NgZone) {
        this.voucherSuccess = '';
        this.voucherError = '';
        this.paymentFormHtml = '';

        this.authorizeNetModel = {
            cardNumber: '',
            cardCode: '',
            month: '',
            year: '',
            fullName: '',
        };
    }
    @ViewChild('paymentForm') paymentFormElement;

    private _bookingSub: Subscription;
    private _calendarDataClasses: DatepickerDateCustomClasses[] = [];
    private _usaEpayClient: any;
    private _usaEpayPaymentCard: any;

    public stripeElements: StripeElements;
    public stripePaymentRequestButton: StripePaymentRequestButtonElement;


    public currentDate: any;
    public currentTab: number;

    public completingBooking = false;
    public canBookSecondary = false;
    public agreeTermsConditions = false;

    public successMessage: string;
    public errorMessage: string;
    public voucherSuccess: string;
    public voucherError: string;

    public minDate: Date;
    public maxDate: Date;
    public isDatePickerOpen = false;
    public voucherInput: string;

    public paymentMethod: string;

    public branchDetails: BranchDetailsModel;
    public rooms: RoomModel[];
    public playerTiers: number[] = [];

    public lodash = _;

    public loading = true;

    public iframeTarget: string;

    public form: FormGroup;
    public detailsForm: FormGroup;
    public termsForm: FormGroup;
    public paymentForm: FormGroup;

    public roomsLoading = true;
    public bookingReference: string;

    public roomSizingClass;
    public bookingDetails: CreateBookingModel = {};

    public apiUrl: string;
    public paymentResponse: any;

    public error: any;
    public validCard = false;
    public element: any;

    public longNameSize = 25;
    public hasLongNames = false;
    public hasShortDescriptions = false;
    public innerWidth = 0;

    public kioskMode = false;
    public kioskTimeout = 30;

    public selectedRoom: RoomModel;

    public roomFilter: number[] = [];

    public braintreeTokenUrl: string;
    public paymentFormHtml: string;


    public squareLoaded = false;
    public payFastLoaded = false;
    public usaEpayLoaded = false;
    public paystackLoaded = false;
    public processingPayment = false;


    public authorizeNetModel: CreditCard;
    public authorizeNetLoaded = false;
    public googleAnalyticsLoaded = false;

    public hasNoSlots = false;

    public elementsOptions: StripeElementsOptions = {
        locale: 'en',
    };

    public cardOptions: StripeCardElementOptions = {
        style: {
            base: {
                iconColor: '#666EE8',
                color: '#31325F',
                fontWeight: '300',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                    color: '#CFD7E0',
                },
            },
            invalid: {
                color: '#FF0000',
                iconColor: '#FF0000',
            },
        },
    };

    public calendarConfig: Partial<BsDatepickerConfig>;

    public PaymentTypeEnum = PaymentTypeEnum;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    public cardUpdated(result: any): void {
        this.element = result.element;
        this.validCard = result.card.complete;
        this.error = undefined;
    }

    public updateCalendarConfig(): void {
        this.calendarConfig = {
            showWeekNumbers: false,
            dateCustomClasses: this._calendarDataClasses,
            containerClass: this._calendarDataClasses.length > 0 ? 'calendar-availability-colours theme-green' : 'theme-green'
        };
    }

    public getUsaEpayToken(): void {
        this.completingBooking = true;

        this._usaEpayClient.getPaymentKey(this._usaEpayPaymentCard).then(async result => {
            if (result.error) {
                let errorContainer = document.getElementById('paymentCardErrorContainer');
                errorContainer.textContent = result.error.message;
            } else {
                // do something with your payment key
                this.bookingDetails.payment.usaEpay.nonce = result;
                await this.completeBooking();
            }
        }).catch( err => {
            this.completingBooking = false;
        });
    }

    public getCardToken(): void {
        this.completingBooking = true;

        this._stripeService.createToken(this.element, {
            name: this.branchDetails.name + ' Booking'
        }).subscribe(result => {
            if (result.token) {
                this.bookingDetails.payment.stripe.nonce = result.token.id;
                this.completeBooking();
            } else {
                // todo: handle error
                this.completingBooking = false;
            }
        });
    }

    public async validateVoucher(): Promise<void> {
        let response = await this._bookingService.validateVoucher(this.voucherInput);


        if (response.success) {
            this.voucherInput = '';
            this.voucherError = '';
            this.voucherSuccess = response.message;
        } else {
            this.voucherSuccess = '';
            this.voucherError = response.message;
        }
    }

    public setRoom(room: RoomModel) {

        if (room && this.bookingDetails.room) {
            if (room.id !== this.bookingDetails.room.id) {
                if (room.gameOptions.length > 0) {
                    this.setGameOption(room.gameOptions[0]);
                } else {
                    this.setGameOption('');
                }
            }
        }
        this.selectedRoom = room;
        this._bookingService.setRoom(room);
    }

    public getSelectedRoom(): RoomModel {
        return this.selectedRoom;
    }

    public async ngOnInit(): Promise<void> {

        this.innerWidth = window.innerWidth;

        this.currentTab = 1;

        this.minDate = new Date();
        this.maxDate = new Date();
        this.minDate.setDate(this.minDate.getDate());
        this.maxDate.setDate(this.maxDate.getDate() + 30);

        this.successMessage = '';
        this.errorMessage = '';

        this._bookingService.getBookingDetails();
        this._bookingSub = this._bookingService.bookingDetails.subscribe(d => {
            this.bookingDetails = d;
        });

        this._bookingService.iframeTarget.subscribe(target => {
            this.iframeTarget = target;
        });

        this._bookingService.setIframeTarget('bpcontainer');

        this.apiUrl = await this._http.getUrl('');
        this.braintreeTokenUrl = this.apiUrl  + 'braintree/token';

        let rSub = this._activatedRoute.params.subscribe(async params => {
            this.currentDate = moment();

            if (params['branchId']) {
                this._bookingService.setBranchId(params['branchId']);
            }

            if (params['branchHash']) {
                this._bookingService.setBranchHash(params['branchHash']);
            }

            if (params['room']) {
                this.roomFilter = params['room'].split(',').map(item => Number(item));
            }

            if (params['kiosk']) {
                this.kioskMode = true;
                this.kioskTimeout = Number(params['kiosk']);
            }

            if (params['target']) {
                this._bookingService.setIframeTarget(params['target']);
            }

            let currentDate = new FormControl(moment().toDate());
            currentDate.valueChanges.subscribe(value => {
                this.currentDate = moment(value, 'MM-DD-YYYY');
                this.bookingDetails.bookingDate = this.currentDate.format('DD/MM/YYYY');
                this.getTimes();
            });

            this.form = this._fb.group({
                currentDate
            });


            // Details Form
            let fullName = new FormControl(null, [Validators.required, Validators.minLength(5)]);
            fullName.valueChanges.subscribe(value => {
                this._bookingService.setCustomerName(value);
            });

            let emailAddress = new FormControl(null, [Validators.required, Validators.email]);
            emailAddress.valueChanges.subscribe(value => {
                this._bookingService.setCustomerEmail(value);
            });

            let phoneNumber = new FormControl(null, [Validators.required, Validators.minLength(5)]);
            phoneNumber.valueChanges.subscribe(value => {
                this._bookingService.setCustomerPhone(value);
            });

            let additionalComments = new FormControl(undefined);
            additionalComments.valueChanges.subscribe(value => {
                this._bookingService.setAdditionalComments(value);
            });

            let additionalCommentsAction = new FormControl(null);
            additionalCommentsAction.valueChanges.subscribe(value => {
                if (value === true) {
                    additionalComments.setValidators(Validators.required);
                    additionalComments.setValue(additionalComments.value);
                } else {
                    additionalComments.setValidators([]);
                    additionalComments.setValue(additionalComments.value);
                }
                this._bookingService.setAdditionalCommentsAction(value);
            });

            let additionalActionAction = new FormControl(null);
            additionalActionAction.valueChanges.subscribe(value => {
                this._bookingService.setAdditionalActionAction(value);
            });

            let additionalActionTwoAction = new FormControl(null);
            additionalActionTwoAction.valueChanges.subscribe(value => {
                this._bookingService.setAdditionalActionTwoAction(value);
            });

            let howDidYouFindUs = new FormControl(null);
            howDidYouFindUs.valueChanges.subscribe(value => {
                this._bookingService.setHowDidYouFindUs(value);
            });

            let howDidYouFindUsOther = new FormControl(null);
            howDidYouFindUsOther.valueChanges.subscribe(value => {
                this._bookingService.setHowDidYouFindUsOther(value);
            });

            this.detailsForm = this._fb.group({
                fullName,
                emailAddress,
                phoneNumber,
                additionalComments,
                additionalCommentsAction,
                additionalActionAction,
                additionalActionTwoAction,
                howDidYouFindUs,
                howDidYouFindUsOther
            });

            let agreeTermsConditions = new FormControl(null, Validators.requiredTrue);
            agreeTermsConditions.valueChanges.subscribe(value => {
                this.agreeTermsConditions = value;
            });

            this.termsForm = this._fb.group({
                agreeTermsConditions
            });

            await this.getBranchDetails();
            await this.getTimes();

            if (this.branchDetails.locale.additionalTerms != null) {
                for (let i = 0; i < this.branchDetails.locale.additionalTerms.length; i++) {
                    this.termsForm.addControl('additionalTerm' + i, new FormControl(null, Validators.requiredTrue));
                }
            }

            this.loading = false;


        });
    }

    public setGameOption(gameOption: string): void {
        this._bookingService.setGameOption(gameOption);
    }

    public hasRequestedButNoComments: ValidatorFn = (fg: FormGroup) => {
        // if (fg == null || fg.parent == null) {
        //     return null;
        // }
        //
        // let additionalCommentsAction = fg.parent.get('additionalCommentsAction');
        // let additionalComments = fg.parent.get('additionalComments');
        //
        // if (additionalCommentsAction != null && additionalCommentsAction.value === true) {
        //     additionalComments.setValidators(Validators.required);
        //     additionalComments.setValue(null);
        //     additionalComments.updateValueAndValidity({ emitEvent: true });
        //     return null;
        // } else {
        //     additionalComments.setValidators(null);
        //     additionalComments.setValue(additionalComments.value);
        //     return null;
        // }

        let additionalCommentsAction = fg.get('additionalCommentsAction');

        if (additionalCommentsAction.value === true) {
            return { 'additionalCommentsRequired': true };
        }
        return null;
    }


    public async processPayFast(onsite = false): Promise<void> {
        this.processingPayment = true;
        if (onsite) {
            const payFastToken = await this._bookingService.getPayFastToken(this.bookingDetails.customerDetails.email, this.bookingDetails.pricingTotals.balanceDue);
            const tokenModel = {
                uuid: payFastToken
            };

            // @ts-ignore
            window.payfast_do_onsite_payment(tokenModel, result => {
                if (result === true) {
                    this.completingBooking = true;
                    this.bookingDetails.payment.payFast.nonce = payFastToken;
                    this.completeBooking();
                } else {
                    this.processingPayment = false;
                }
            });

        } else {
            this.completingBooking = true;
            this.bookingDetails.payment.payFast.nonce = 'offsite';
            this.completeBooking();
        }


    }

    public async processAuthorizeNet(): Promise<void> {
        try {
            let creditCardModel = _.clone(this.authorizeNetModel);
            let expiry = creditCardModel.year.split('/');

            creditCardModel.month = expiry[0].replace(/\s/g, '');
            creditCardModel.year = expiry[1].replace(/\s/g, '');
            creditCardModel.cardNumber = creditCardModel.cardNumber.replace(/\s/g, '');

            const nonce = await this._acceptJSSrv.generatePaymentNonce(creditCardModel);
            this._bookingService.setAuthorizeNetNonce(nonce);

            this.completeBooking();
        } catch (ex) {
            console.log(ex);
            this.errorMessage = ex.messages.message[0].text;
        }
    }

    public canSaveDetails(): boolean {
        return !(this.bookingDetails.totalPlayers <= 0
            || !this.bookingDetails.time.id
            || (this.bookingDetails.room.players.minimumPlayers > this.bookingDetails.totalPlayers)
            || (this.bookingDetails.room.players.maximumPlayers < this.bookingDetails.totalPlayers)
        );
    }

    public getPrice(primary: boolean): number {
        return this._bookingService.getPrice(primary);
    }

    public getUpgradePrice(): number {
        return this._bookingService.getUpgradePrice();
    }

    public stripePaymentRequestOptions(): PaymentRequestOptions {
        return {
            country: 'NZ', // Change to your merchant country code
            currency: 'nzd',
            total: {
                label: this.branchDetails.name + ' Booking',
                amount: (this.bookingDetails.pricingTotals.total * 100),
            },
            requestPayerName: true,
            requestPayerEmail: true,
        };
    }

    public async getBranchDetails(): Promise<void> {
        this.branchDetails = await this._http.get<BranchDetailsModel>(`branch`);

        // Check if we need to set a stripe key
        if (this.branchDetails.paymentProcessors.stripe !== null) {
            if (this.branchDetails.paymentProcessors.stripe.enabled && this.branchDetails.paymentProcessors.stripe.publicKey != '') {
                this._stripeService.changeKey(this.branchDetails.paymentProcessors.stripe.publicKey);
            }
        }

        if (this.branchDetails.paymentProcessors.payFast && this.branchDetails.paymentProcessors.payFast.enabled && this.payFastLoaded === false) {
            let node = document.createElement('script');

            if (this.branchDetails.paymentProcessors.payFast.sandbox) {
                node.src = 'https://sandbox.payfast.co.za/onsite/engine.js';
            } else {
                node.src = 'https://www.payfast.co.za/onsite/engine.js';
            }

            node.type = 'text/javascript';
            node.async = true;
            node.charset = 'utf-8';
            document.getElementsByTagName('head')[0].appendChild(node);

            this.payFastLoaded = true;
        }

        if (this.branchDetails.paymentProcessors.square && this.branchDetails.paymentProcessors.square.enabled && this.squareLoaded === false) {
            let node = document.createElement('script');

            if (this.branchDetails.paymentProcessors.square.sandbox) {
                node.src = 'https://sandbox.web.squarecdn.com/v1/square.js';
            } else {
                node.src = 'https://web.squarecdn.com/v1/square.js';
            }

            node.type = 'text/javascript';
            node.async = true;
            node.charset = 'utf-8';
            document.getElementsByTagName('head')[0].appendChild(node);

            this.squareLoaded = true;
        }


        this._bookingService.setBranch(this.branchDetails);

        if (this.branchDetails.darkTheme) {
            this.document.getElementById('theme').setAttribute('href', 'src/assets/clr-ui-dark.min.css');
            this.cardOptions.style.base.color = '#acbac3';
        } else {
            this.document.getElementById('theme').setAttribute('href', 'src/assets/clr-ui.min.css');
        }

        if (this.branchDetails.cssOverrides !== '') {
            const styleElement = document.createElement('style');
            styleElement.appendChild(document.createTextNode(this.branchDetails.cssOverrides));
            this._el.nativeElement.appendChild(styleElement);
        }

        this.document.getElementById('body').classList.add('theme-' + (this.branchDetails.darkTheme ? 'dark' : 'light'));

        this.paymentForm = this._fb.group({});

        if (this.branchDetails.tracking.google.id) {
            window.top.postMessage({
                event: 'begin_checkout',
                gtagData: {
                    ecommerce: {}
                }
            }, '*');
        }

        if (this.branchDetails.tracking.google.id) {
            // this._googleAnalyticsService.loadGoogleAnalytics(this.branchDetails.tracking.google.id);
            // this._angularticsGoogle.startTracking();
        }

        if (this.branchDetails.calendarColourCodeDays) {
            this._calendarDataClasses = await this._bookingService.getCalendarDateClasses();
        }

        this.updateCalendarConfig();

        // if (this.branchDetails.tracking.google.gaId !== '' && !this.googleAnalyticsLoaded) {
        //
        //     let analyticsScript = document.createElement('script');
        //
        //     analyticsScript.src = 'https://www.google-analytics.com/analytics.js';
        //     analyticsScript.async = true;
        //     analyticsScript.type = 'text/javascript';
        //     document.getElementsByTagName('head')[0].appendChild(analyticsScript);
        //
        //     let analyticsTrack = document.createElement('script');
        //
        //     analyticsTrack.text = 'window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;\n' +
        //         'ga(\'create\', \'' + this.branchDetails.tracking.google.gaId + '\', \'auto\');\n' +
        //         'ga(\'send\', \'pageview\');';
        //     analyticsTrack.type = 'text/javascript';
        //     document.getElementsByTagName('head')[0].appendChild(analyticsTrack);
        //
        //     this._angularticsGoogleAnalytics.startTracking();
        //     this.googleAnalyticsLoaded = true;
        // }

        // if (this.branchDetails.tracking.facebook.id) {
        //     this._facebookAnalyticsService.loadFacebookPixel(this.branchDetails.tracking.facebook.id);
        //     this._angularticsFacebook.startTracking();
        // }

        if (this.branchDetails.cutOffDays) {
            this.maxDate = new Date();
            this.maxDate.setDate(this.maxDate.getDate() + this.branchDetails.cutOffDays);
        }

        const enabledPaymentProviders = this.getEnabledPaymentAbbreviations(this.branchDetails.paymentProcessors);
        if (enabledPaymentProviders.length === 1) {
            this.setPaymentMethod(enabledPaymentProviders[0], this.branchDetails.paymentProcessors.depositOnly || false);
        }
    }

    public async getTimes(): Promise<void> {
        this.roomsLoading = true;
        this.rooms = [];

        this.selectTime({});

        let allRooms: RoomModel[] = await this._http.get<RoomModel[]>(`availability?d=${this.currentDate.format('DD/MM/YYYY')}`);

        if (this.roomFilter.length > 0) {
            for (let room of allRooms) {
                if (this.roomFilter.includes(room.id)) {
                    let index = this.rooms.map(a => a.id).indexOf(room.id);
                    this.rooms.push(room);

                    if (!this.getSelectedRoom()) { this.setRoom(room); }
                }
            }
        } else {
            this.rooms = allRooms;
            if (!this.selectedRoom || !this.selectedRoom.id) {
                this.setRoom(this.rooms[0]);  // Set the first room to be active if not already selected
            }
        }

        this.hasShortDescriptions = false;
        for (let room of allRooms) {
            if (room.shortDescription) {
                this.hasShortDescriptions = true;
                break;
            }
        }

        this.hasNoSlots = true;
        for (let room of allRooms) {
            if (room.times != null && room.times.length > 0) {
                this.hasNoSlots = false;
                break;
            }
        }

        if (this.rooms.length === 1) {
            this.roomSizingClass = 'clr-col-md-4 clr-col-12';
        } else if (this.rooms.length === 2) {
            this.roomSizingClass = 'clr-col-md-4 clr-col-12';
        } else if (this.rooms.length === 3) {
            this.roomSizingClass = 'clr-col-md-4 clr-col-12';
        }  else if (this.rooms.length === 4) {
            this.roomSizingClass = 'clr-col-md-3 clr-col-12';
        } else if (this.rooms.length === 5) {
            this.roomSizingClass = 'clr-col-lg-5ths clr-col-md-5ths clr-col-sm-6 clr-col-12';
        } else if (this.rooms.length >= 5) {
            this.roomSizingClass = 'clr-col-lg-2 clr-col-sm-6 clr-col-12';
        } else {
            this.roomSizingClass = 'clr-col-lg-3 clr-col-sm-6 clr-col-12';
        }

        this.roomsLoading = false;
    }

    public selectTime(time: RoomTimeModel): void {
        if (time.id > 0) {
            const room = this.rooms.find(x => x.id === time.roomId);
            this._bookingService.setTime(time);
            this.setRoom(room);
            this._bookingService.setDate(this.currentDate.format('DD/MM/YYYY'));

            this.playerTiers = [];
            if (this.branchDetails.tierPricingBooking) {
                for (let price of room.pricing) {
                    this.playerTiers.push(price.tier);
                }
            }

            if (this.branchDetails.tracking.google.id) {
                window.top.postMessage({
                    event: 'select_timeslot',
                    gtagData: {
                        date: this.bookingDetails.bookingDate,
                        time: this.bookingDetails.time.formattedTime,
                        name: this.bookingDetails.room.name
                    }
                }, '*');
            }
        } else {
            this._bookingService.setTime(null);
            this.setRoom(null);
            this._bookingService.setDate(null);
            this._bookingService.setGameOption('');
        }
    }

    public createPurchase(nonce: string, chargeAmount: number): Observable<any> {
        this._bookingService.setBraintreeNonce(nonce);

        this.completeBooking();

        return new Observable<any>();
    }

    public canGoPrevious(): boolean {
        let currentDate = moment(this.currentDate).subtract(1, 'day').startOf('day');
        let minDate = moment(this.minDate).startOf('day');

        if (currentDate.isSameOrAfter(minDate)) {
            return true;
        } return false;
    }

    public canGoNext(): boolean {
        let currentDate = moment(this.currentDate);
        let maxDate = moment(this.maxDate).subtract(1, 'day');

        if (currentDate.isSameOrBefore(maxDate)) {
            return true;
        } return false;
    }

    public goPrevious() {
        this.currentDate = moment(this.currentDate).subtract(1, 'day');
        this.getTimes();
    }

    public goNext() {
        this.currentDate = moment(this.currentDate).add(1, 'day');
        this.getTimes();
    }

    public setTotalPlayers(totalPlayers: number): void {
        this._bookingService.setTotalPlayers(1);
        this._bookingService.setSecondaryPlayers(1);

        let secondPlayerPrice = this.getPrice(false);
        if (secondPlayerPrice > 0) {
            this.canBookSecondary = true;
        } else {
            this.canBookSecondary = false;
        }
        this._bookingService.setTotalPlayers(totalPlayers);
        this._bookingService.setSecondaryPlayers(0);
        this.triggerAnalytics();
    }

    public setSecondaryPlayers(secondaryPlayers: number): void {
        this._bookingService.setSecondaryPlayers(secondaryPlayers);
        this.triggerAnalytics();
    }

    public triggerAnalytics(): void {
        const playerOnePrice = this.bookingDetails.pricingTotals.playerOnePrice * (this.bookingDetails.totalPlayers - this.bookingDetails.totalSecondaryPlayers);
        const playerTwoPrice = this.bookingDetails.pricingTotals.playerTwoPrice * this.bookingDetails.totalSecondaryPlayers;

        if (this.branchDetails.tracking.facebook.enabled) {
            window.top.postMessage({
                event: 'AddToCart',
                fbqData: {
                    currency: this.branchDetails.locale.currencyCode,
                    content_name: this.branchDetails.locale.playerOneName,
                    content_category: 'Tickets',
                    value: playerOnePrice
                }
            }, '*');

            if (this.bookingDetails.totalSecondaryPlayers > 0) {
                window.top.postMessage({
                    event: 'AddToCart',
                    fbqData: {
                        currency: this.branchDetails.locale.currencyCode,
                        content_name: this.branchDetails.locale.playerTwoName,
                        content_category: 'Tickets',
                        value: playerTwoPrice
                    }
                }, '*');
            }
        }

        if (this.branchDetails.tracking.google.id) {
            window.top.postMessage({
                event: 'add_to_cart',
                gtagData: {
                    currency: this.branchDetails.locale.currencyCode,
                    value: this.bookingDetails.pricingTotals.total,
                    items: [{
                        item_name: this.branchDetails.locale.playerOneName,
                        quantity: this.bookingDetails.totalPlayers - this.bookingDetails.totalSecondaryPlayers,
                        item_category: 'Tickets',
                        item_category2: this.bookingDetails.room.name,
                        price: playerOnePrice
                    }, {
                        item_name: this.branchDetails.locale.playerTwoName,
                        quantity: this.bookingDetails.totalSecondaryPlayers,
                        item_category: 'Tickets',
                        item_category2: this.bookingDetails.room.name,
                        price: playerTwoPrice
                    }]
                }
            }, '*');
        }
    }

    public goToTab(tab: number) {
        this.currentTab = tab;
        window.top.postMessage('scrollTop-' + this.iframeTarget, '*');
        window.scroll(0, 0);
    }


    public setPaymentMethod(method: string, deposit: boolean, displayName?: string): void {
        this.paymentMethod = method;

        if (displayName) {
            this.bookingDetails.payment.name = displayName;
        }
        
        if(this.paymentMethod === 'stripe' && this.stripeLoaded === false) {
            this._stripeService.elements(this.elementsOptions).subscribe(elements => {
                this.stripeElements = elements;

                // Initialize Payment Request Button
                const stripe = this._stripeService.getInstance();
                const paymentRequest = stripe.paymentRequest(this.stripePaymentRequestOptions());

                // Check for availability
                paymentRequest.canMakePayment().then(result => {
                    if (result) {
                        this.stripePaymentRequestButton = elements.create('paymentRequestButton', {
                            paymentRequest,
                            style: {
                                paymentRequestButton: {
                                    type: 'default',
                                    theme: 'dark',
                                    height: '40px',
                                },
                            },
                        });
                        this.stripePaymentRequestButton.mount('#payment-request-button');
                        this.stripeLoaded = true;
                    } else {
                        console.error('Payment Request Button not supported');
                    }
                });
            });

        }

        if (this.paymentMethod === 'ccusa' && this.usaEpayLoaded === false) {

            let node = document.createElement('script');

            if (this.branchDetails.paymentProcessors.usaEpay.sandbox) {
                node.src = 'https://sandbox.usaepay.com/js/v1/pay.js';
            } else {
                node.src = 'https://www.usaepay.com/js/v1/pay.js';
            }

            node.type = 'text/javascript';
            node.async = true;
            node.charset = 'utf-8';

            node.onload = () => {
                // @ts-ignore
                this._usaEpayClient = new usaepay.Client(this.branchDetails.paymentProcessors.usaEpay.publicKey);
                this._usaEpayPaymentCard = this._usaEpayClient.createPaymentCardEntry();

                let style = {
                    'base': {
                        'height': '40px',

                    }
                };

                this._usaEpayPaymentCard.generateHTML(style);

                this._usaEpayPaymentCard.addHTML('paymentCardContainer');

                this._usaEpayPaymentCard.addEventListener('error', errorMessage => {
                    let errorContainer = document.getElementById('paymentCardErrorContainer');
                    errorContainer.textContent = errorMessage;
                });
            };


            document.getElementsByTagName('head')[0].appendChild(node);





            this.usaEpayLoaded = true;

        }

        if (this.paymentMethod === 'ccps' && this.usaEpayLoaded === false) {

            let node = document.createElement('script');

            if (this.branchDetails.paymentProcessors.paystack.sandbox) {
                node.src = 'https://js.paystack.co/v1/inline.js';
            } else {
                node.src = 'https://js.paystack.co/v1/inline.js';
            }

            node.type = 'text/javascript';
            node.async = true;
            node.charset = 'utf-8';

            // node.onload = () => {
            //     // @ts-ignore
            //     const paymentForm = document.getElementById('paymentForm');
            //     paymentForm.addEventListener('submit', this.payWithPaystack, false);
            // };
            //
            document.getElementsByTagName('head')[0].appendChild(node);

            this.paystackLoaded = true;
        }

        if (this.paymentMethod === 'ccauthorizenet' && !this.authorizeNetLoaded) {
            let url = 'https://js.authorize.net/v1/Accept.js';

            if (this.branchDetails.paymentProcessors.authorizeNet.testMode) {
                url = 'https://jstest.authorize.net/v1/Accept.js';
            }

            this._acceptJSSrv = new AcceptJSService({
                acceptjsUrl: url,
                apiLoginID: this.branchDetails.paymentProcessors.authorizeNet.loginId,
                clientKey: this.branchDetails.paymentProcessors.authorizeNet.publicKey
            }, document);

            this.authorizeNetLoaded = true;
        }
        this._bookingService.setPaymentDeposit(deposit);
    }

    public getPaystackToken(): void {
        // @ts-ignore
        let handler = PaystackPop.setup({
            key: this.branchDetails.paymentProcessors.paystack.publicKey,
            email: this.bookingDetails.customerDetails.email,
            amount: (this.bookingDetails.pricingTotals.total * 100),
            currency: this.branchDetails.paymentProcessors.paystack.currencyCode,
            onClose: function() {
            },
            callback: this.handlePaystackToken.bind(this)
        });

        handler.openIframe();
    }

    public async handlePaystackToken(response): Promise<void> {
        this.bookingDetails.payment.paystack.nonce = response.reference;
        await this.completeBooking();
    }

    public setUpgrade(upgrade: boolean) {
        this._bookingService.setUpgrade(upgrade);
    }

    public onPaymentStatus(response: any) {
        this.paymentResponse = response;
    }

    public handleSquareNonce(nonce: any) {
        if (nonce != '') {
            this.bookingDetails.payment.square.nonce = nonce;
            this.completeBooking();
        }
    }


    public async completeBooking(): Promise<void> {
        this.completingBooking = true;
        this.errorMessage = '';

        let bookingData = _.clone(this.bookingDetails);
        if (bookingData.howDidYouFindUsOther) { bookingData.howDidYouFindUs = bookingData.howDidYouFindUsOther; }

        /*
         *  Override since its deposit ONLY
         */
        if (this.branchDetails.paymentProcessors.depositOnly === true) {
            this._bookingService.setPaymentDeposit(true);
        }

        try {
            let data = await this._http.post<any>(`booking`, { booking: bookingData });

            if (data.errorMessage) {
                this.errorMessage = data.errorMessage;
            } else {
                this.bookingReference = data.bookingReference;
                this.successMessage = data.successMessage;
                this._bookingService.setBookingReference(this.bookingReference);

                // https://developers.google.com/analytics/devguides/collection/ga4/reference/events
                if (this.branchDetails.tracking.google.id) {
                    window.top.postMessage({
                        event: 'purchase',
                        gtagData: {
                            value: this.bookingDetails.pricingTotals.total,
                            coupon: this.bookingDetails.coupon.code,
                            payment_type: this.bookingDetails.payment.name,
                            currency: this.branchDetails.locale.currencyCode,
                            items: [{
                                item_name: this.branchDetails.locale.playerOneName,
                                quantity: this.bookingDetails.totalPlayers - this.bookingDetails.totalSecondaryPlayers,
                                item_category: 'Tickets',
                                item_category2: this.bookingDetails.room.name,
                                price: this.bookingDetails.pricingTotals.playerOnePrice * (this.bookingDetails.totalPlayers - this.bookingDetails.totalSecondaryPlayers)
                            }, {
                                item_name: this.branchDetails.locale.playerTwoName,
                                quantity: this.bookingDetails.totalSecondaryPlayers,
                                item_category: 'Tickets',
                                item_category2: this.bookingDetails.room.name,
                                price: this.bookingDetails.pricingTotals.playerTwoPrice * this.bookingDetails.totalSecondaryPlayers
                            }]
                        }
                    }, '*');
                }

                if (this.branchDetails.tracking.google.targetId) {
                    window.top.postMessage({
                        event: 'conversion',
                        gtagData: {
                            send_to: this.branchDetails.tracking.google.targetId,
                            currency: this.branchDetails.locale.currencyCode,
                            value: this.bookingDetails.pricingTotals.total,
                        }
                    }, '*');
                }

                // if (this.googleAnalyticsLoaded) {
                //     let analyticsTrack = document.createElement('script');
                //     analyticsTrack.text = 'ga(\'send\', {\n' +
                //         '  hitType: \'event\',\n' +
                //         '  eventCategory: \'Booking\',\n' +
                //         '  eventAction: \'Buy Tickets\',\n' +
                //         '  eventLabel: \'Booking ' + this.bookingReference + '\'\n' +
                //         '  eventValue: \'' + this.bookingDetails.pricingTotals.total + '\'\n' +
                //         '});';
                //     analyticsTrack.type = 'text/javascript';
                //     document.getElementsByTagName('head')[0].appendChild(analyticsTrack);
                // }

                if (this.branchDetails.tracking.facebook.enabled) {
                    window.top.postMessage({
                        event: 'Purchase',
                        fbqData: {
                            currency: this.branchDetails.locale.currencyCode,
                            amount: this.bookingDetails.pricingTotals.total
                        }
                    }, '*');
                }

                if (this.kioskMode === true && this.kioskTimeout > 0) {
                    setTimeout(function() {
                        location.reload();
                    }, this.kioskTimeout * 1000);
                } else {
                    if (data.redirectUrl) {
                        if (data.redirectParent) {
                            window.top.location.href = data.redirectUrl;
                        } else {
                            this.document.location.href = data.redirectUrl;
                        }
                    }
                }
            }
            this.completingBooking = false;
        } catch (e) {
            this.errorMessage = 'Unknown error, please try again';
            this.completingBooking = false;
        }
    }

    private getEnabledPaymentAbbreviations(branchDetailsPayment: BranchDetailsPaymentModel): string[] {
        const enabledPayments: string[] = [];

        // Iterate through all keys in the BranchDetailsPaymentModel
        for (const key in branchDetailsPayment) {
            if (branchDetailsPayment.hasOwnProperty(key)) {
                const paymentMethod = branchDetailsPayment[key];

                // Check if the payment method is an object and has 'enabled' property set to true
                if (paymentMethod && typeof paymentMethod === 'object' && paymentMethod['enabled'] === true) {
                    enabledPayments.push(key);
                }
            }
        }

        return enabledPayments;
    }
}
