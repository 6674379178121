import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    private config: any;

    constructor(private http: HttpClient) {}

    public loadConfig(): Promise<void> {
        return this.http
            .get('/config.json')
            .toPromise()
            .then((config) => {
                this.config = config;
            })
            .catch((error) => {
                console.error('Failed to load config.json', error);
                throw error;
            });
    }

    public getConfig(): any {
        return this.config;
    }
}
