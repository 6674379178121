import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigModel } from '../app/config.model';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    private config: ConfigModel;

    public initialize(config: any): void {
        this.config = config;
    }

    public get apiAddress(): string {
        return this.config && this.config.apiUrl != null ? this.config.apiUrl : '';
    }
}
