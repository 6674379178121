import {Component, Input} from '@angular/core';

@Component({
    selector: 'bp-read-more',
    template: `
        <div [class.collapsed]="isCollapsed">
            <ng-content></ng-content>
        </div>
        <ng-container *ngIf="showReadMore">
            <div (click)="isCollapsed = !isCollapsed" *ngIf="isCollapsed" class="btn btn-sm btn-link clr-float-xs-right">Show more</div>
            <div (click)="isCollapsed = !isCollapsed" *ngIf="!isCollapsed" class="btn btn-sm btn-link clr-float-xs-right">Show less</div>
        </ng-container>
    `,
    styles: [`
        div.collapsed {
            max-height: 45px;
            overflow: hidden;
        }
    `]
})

export class ReadMoreComponent {
    @Input() showReadMore: boolean;

    isCollapsed = true;
}