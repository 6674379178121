import {Component, OnInit} from '@angular/core';

import {HttpClientService} from '../core/http-client/http-client.service';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {ModalService} from '../modals/modal.service';
import {BookingService} from '../services/booking/booking.service';
import {GoogleAnalyticsService} from '../services/google-analytics/google-analytics.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public pollId: any;
  public idleState = '';
  public timedOut = false;
  public timingOut = false;
  public lastPing?: Date = null;
  public iframeTarget: string;

  constructor(private _http: HttpClientService,
              private _idle: Idle,
              private _keepalive: Keepalive,
              private _modalService: ModalService,
              private _bookingService: BookingService) {
    //this._googleAnalyticsService.startTracking();
  }

  public async ngOnInit(): Promise<void> {

    this.poll();
    this.pollId = setInterval(() => {
      this.poll();
    }, 500);



    this._idle.setIdle(300);  // after 5mins
    this._idle.setTimeout(30); // 30 seconds
    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this._idle.onIdleEnd.subscribe(() => this.idleState = '');
    this._idle.onTimeout.subscribe(() => {
      if (!this._bookingService.getBookingReference()) {
        this.idleState = 'Session timed out, please refresh the page to start again.';
        this._modalService.openModal(this._modalService.modalTypes.timeout);
        this.timedOut = true;
      }
    });
    this._idle.onTimeoutWarning.subscribe((countdown) => {
      if (!this._bookingService.getBookingReference()) {
        this.idleState = 'Inactivity detected, your session will expire in ' + countdown + ' seconds!';
        this.timingOut = true;
      }
    });

    // sets the ping interval to 15 seconds
    this._keepalive.interval(15);

    this._keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
      this.timingOut = false;
    });

    this._bookingService.iframeTarget.subscribe(target => {
      this.iframeTarget = target;
    });

    this.resetTimeout();
  }

  public resetTimeout(): void {
    this._idle.watch();
    this.idleState = '';
    this.timedOut = false;
  }

  public poll(): void {
    if (!this.timedOut && !this.timingOut) { window.top.postMessage('height-' + this.getHeight() + '-' + this.iframeTarget, '*'); }
  }

  public getHeight(): number {
    //return document.getElementsByTagName('html')[0].scrollHeight;
    return document.body.scrollHeight; // https://bugs.webkit.org/show_bug.cgi?id=184694
  }

  public scrollToTop(): void {
    window.top.postMessage('scrollBottom-' + this.iframeTarget, '*');
  }

  public scrollToBottom(): void {
    window.top.postMessage('scrollBottom-' + this.iframeTarget, '*');
  }
}
