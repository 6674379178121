import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClarityModule } from '@clr/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app.component';
import { MainComponent } from './components/main/main.component';
import { ErrorComponent } from './components/error/error.component';
import { HttpClientService } from './core/http-client/http-client.service';
import { Interceptor } from './core/http-client/interceptor.service';
import { SettingsService } from './core/settings/settings.service';
import { ModalModule } from './modals/modal.module';
import { NgxBraintreeModule } from 'ngx-braintree';
import { BookingSummaryComponent } from './components/booking-summary/booking-summary.component';
import { BookingService } from './services/booking/booking.service';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import {SafePipe} from './pipes/safe-pipe.pipe';
import {Angulartics2Module} from 'angulartics2';
import { TK_CONFIG, Config, AcceptJSService } from '@openutility/acceptjs-angular-wrapper';
import {SquareUpComponent} from './components/payments/squareup/squareup.component';
import { nl2brPipe } from './pipes/nl2br-pipe.pipe';
import {CreditCardDirectivesModule} from 'angular-cc-library';
import {AppConfigService} from './core/app/app-config.service';
import {NgxStripeModule} from "ngx-stripe";

const AcceptJSConfig: Config = {
  acceptjsUrl: '',
  apiLoginID: '',
  clientKey: ''
};

export function initializeApp(appConfigService: AppConfigService, settingsService: SettingsService) {
  console.log('Initializing App');
  return () =>
      appConfigService.loadConfig().then(() => {
        const config = appConfigService.getConfig();
        settingsService.initialize(config);
      });
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ErrorComponent,
    BookingSummaryComponent,
    ReadMoreComponent,
    SquareUpComponent,
    SafePipe,
    nl2brPipe
  ],
  imports: [
    BrowserModule,
    ClarityModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    ModalModule,
    NgxBraintreeModule,
    BsDatepickerModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    NgxStripeModule.forRoot('pk_test_nuunKdqW1xEZQ1YGWdQAVsr3'),
    Angulartics2Module.forRoot(),
    CreditCardDirectivesModule
  ],
  providers: [
    AppConfigService,
    SettingsService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService, SettingsService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    HttpClientService,
    BookingService,
    { provide: TK_CONFIG, useValue: AcceptJSConfig },
    AcceptJSService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
