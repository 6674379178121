import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ModalService } from '../modal.service';

@Component({
    selector: 'ss-server-error',
    templateUrl: './server-error.modal.component.html'
})
export class ServerErrorModalComponent implements OnInit, OnDestroy {
    private _msSub: Subscription;
    public showModal: boolean;

    constructor(private _modalService: ModalService) { }

    public async ngOnInit(): Promise<void> {
        this._msSub = this._modalService.modal.subscribe(m => {
            if (m != null && m.type === this._modalService.modalTypes.serverError) {
                this.showModal = true;
            }
        });
    }

    public ngOnDestroy(): void {
        if (this._msSub != null) {
            this._msSub.unsubscribe();
        }
    }

    public closeModal() {
        this._modalService.closeModal();
        this.showModal = false;
        window.location.reload();
    }
}