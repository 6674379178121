import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import {BookingService} from '../booking/booking.service';
import {BranchDetailsModel} from '../../components/main/main.component.model';
import {Subscription} from 'rxjs';

declare var gtag: any;
declare var window: any;


@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {
    private _branchSubscription: Subscription;
    public branchDetails: BranchDetailsModel;

    constructor(
        private _angulartics: Angulartics2GoogleGlobalSiteTag,
        private _bookingService: BookingService,
        @Inject(DOCUMENT) document: any) {}

    public loadGoogleAnalytics(trackingId: string) {
        // injecting GA main script asynchronously
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
        script.async = true;
        document.body.appendChild(script);

        // preparing GA API to be usable even before the async script is loaded
        window.dataLayer = window.dataLayer || [];
        window.gtag = function() {
            window.dataLayer.push(arguments);
        };
        gtag('js', new Date());

        // tracking current url at app bootstrap
        gtag('config', '${trackingId}');
    }
}