import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {BranchDetailsModel, CreateBookingCouponModel, CreateBookingModel} from '../main/main.component.model';
import {BookingService} from '../../services/booking/booking.service';
import * as moment from 'moment';
import {HttpClientService} from '../../core/http-client/http-client.service';

@Component({
    selector: 'bp-booking-summary',
    templateUrl: './booking-summary.component.html'
})
export class BookingSummaryComponent implements OnInit {
    private _bookingSub: Subscription;
    private _branchSub: Subscription;

    public bookingDetails: CreateBookingModel;
    public branchDetails: BranchDetailsModel;
    public bookingDate: any;

    public activeDiscountCode: string;
    public couponInputValue: string;


    constructor(private _router: Router,
                private _bookingService: BookingService,
                private _http: HttpClientService) { }

    public ngOnInit(): void {
        this._bookingService.getBookingDetails();
        this._bookingSub = this._bookingService.bookingDetails.subscribe(d => {
            this.bookingDetails = d;
            this.bookingDate = moment(this.bookingDetails.bookingDate, 'DD/MM/YYYY');
        });

        this._bookingService.getBranchDetails();
        this._branchSub = this._bookingService.branchDetails.subscribe(d => {
            this.branchDetails = d;
        });
    }

    public getPrice(primary: boolean): number {
        return this._bookingService.getPrice(primary);
    }

    public getUpgradePrice(): number {
        return this._bookingService.getUpgradePrice();
    }

    public removeDiscountCoupon(): void {
        this._bookingService.setCoupon({});
    }

    public removeVoucher(code: string): void {
        this._bookingService.removeVoucher(code);
    }

    public async checkDiscountCoupon(): Promise<void> {
        let coupon = await this._http.get<CreateBookingCouponModel>(`coupon.php?b=${this._bookingService.getBranchId()}&c=${this.couponInputValue}&d=${this.bookingDetails.bookingDate}&t=${this.bookingDetails.room.id}&s=${this.bookingDetails.time.id}`);
        this._bookingService.setCoupon(coupon);
    }

    public setDiscountCoupon(event: any): void {
        this.couponInputValue = event.target.value;
    }

}