import {AfterViewInit, Component, OnInit, NgModule, Input, Output, EventEmitter} from '@angular/core';
import {BranchDetailsPaymentSquareModel} from '../../main/main.component.model';

declare var SqPaymentForm: any;

@Component({
    selector: 'bp-squareup',
    templateUrl: './squareup.component.html'
})

export class SquareUpComponent implements OnInit {

    @Input() chargeAmount: number;
    @Input() buttonText: string;
    @Input() tokenData: BranchDetailsPaymentSquareModel;
    @Input() darkMode: boolean;
    @Input() hasChargeError: boolean = false;

    @Output() paymentNonce = new EventEmitter<any>();

    constructor() {}

    paymentForm;
    public card: any;
    public loading = true;
    public errorMessage: string;

    public async ngOnInit(): Promise<void> {
        let applicationId = this.tokenData.applicationId;
        let locationId = this.tokenData.locationId;
        let currencyCode = this.tokenData.currencyCode;
        let countryCode = this.tokenData.countryCode;
        let chargeAmount = this.chargeAmount;
        let darkMode = this.darkMode;

        this.errorMessage = '';



        // @ts-ignore
        if (!window.Square) {
            throw new Error('Square.js failed to load properly');
        }

        let payments;
        try {
            // @ts-ignore
            payments = window.Square.payments(applicationId, locationId);
        } catch {
            const statusContainer = document.getElementById(
                'payment-status-container'
            );
            statusContainer.className = 'missing-credentials';
            statusContainer.style.visibility = 'visible';
            return;
        }

        try {
            this.card = await this.initializeCard(payments);
            this.loading = false;
        } catch (e) {
            console.error('Initializing Card failed', e);
            return;
        }
    }

    nonceResp(errors, nonce, cardData)  {
        if (errors) {
            // Log errors from nonce generation to the Javascript console
            this.errorMessage = 'Please fix the following issues: ';
            for (let e of errors) {
                this.errorMessage = this.errorMessage + ', ' + e.message;
            }

            this.loading = false;
            return;
        }

        this.errorMessage = '';
        this.paymentNonce.emit(nonce);
    }
    public async requestCardNonce(event): Promise<void> {
        event.preventDefault();

        try {
            // disable the submit button as we await tokenization and make a payment request.
            this.loading = true;
            const token = await this.tokenize(this.card);
            this.paymentNonce.emit(token);
        } catch (e) {
            this.loading = false;
            this.displayPaymentResults('FAILURE');
            console.error(e.message);
        }
    }

    paymentFormLoaded() {
        this.loading = false;
    }

    public async tokenize(paymentMethod): Promise<string> {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(
                    tokenResult.errors
                )}`;
            }

            throw new Error(errorMessage);
        }
    }

    public async initializeCard(payments): Promise<any> {
        const card = await payments.card();
        if (this.darkMode) {
            card.configure({
                '.input-container': {
                    borderColor: '#2D2D2D',
                    borderRadius: '6px',
                },
                '.input-container.is-focus': {
                    borderColor: '#006AFF',
                },
                '.input-container.is-error': {
                    borderColor: '#ff1600',
                },
                '.message-text': {
                    color: '#999999',
                },
                '.message-icon': {
                    color: '#999999',
                },
                '.message-text.is-error': {
                    color: '#ff1600',
                },
                '.message-icon.is-error': {
                    color: '#ff1600',
                },
                input: {
                    backgroundColor: '#2D2D2D',
                    color: '#FFFFFF',
                    fontFamily: 'helvetica neue, sans-serif',
                },
                'input::placeholder': {
                    color: '#999999',
                },
                'input.is-error': {
                    color: '#ff1600',
                },
            })
        }
        await card.attach('#card-container');

        return card;
    }

    public displayPaymentResults(status) {
        console.info(status);
    }
}
