import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ModalModel } from './modal.model';

@Injectable()
export class ModalService {
    public modalTypes = {
        timeout: 'timeout',
        serverError: 'serverError',
    };

    private _modal: ModalModel;

    public modal: BehaviorSubject<ModalModel>;

    constructor() {
        this.modal = new BehaviorSubject(null);
    }

    public openModal(type: string): void {
        this._modal = {
            type
        };

        this.modal.next(this._modal);
    }

    public closeModal(): void {
        this._modal = null;
        this.modal.next(this._modal);
    }
}
