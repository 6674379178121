export interface BranchDetailsModel {
    name?: string;
    contact?: BranchDetailsContactModel;
    taxRate?: number;
    taxName?: string;
    taxInclusive?: boolean;
    allowVouchers?: boolean;
    allowCoupons?: boolean;
    allowUpgrades?: boolean;
    allowQuickSelect?: boolean;
    showDescriptions?: boolean;
    layoutStyle?: string;
    rooms?: RoomModel[];
    locale?: BranchDetailsLocaleModel;
    darkTheme?: boolean;
    paymentProcessors?: BranchDetailsPaymentModel;
    terms?: string;
    tracking?: BranchDetailsTrackingModel;
    cutOffDays?: number;
    calculateAllPlayers?: boolean;
    backgroundHex?: string;
    tierPricingBooking?: boolean;
    cssOverrides?: string;
    calendarColourCodeDays?: boolean;
}

export interface BranchDetailsTrackingModel {
    google?: BranchDetailsTrackingGoogleModel;
    facebook?: BranchDetailsTrackingFacebookModel;
}

export interface BranchDetailsTrackingGoogleModel {
    id?: string;
    targetId?: string;
    gaId?: string;
}

export interface BranchDetailsTrackingFacebookModel {
    enabled?: boolean;
    id?: string;
}

export interface BranchDetailsPaymentModel {
    braintree?: BranchDetailsPaymentBraintreeModel;
    stripe?: BranchDetailsPaymentStripeModel;
    square?: BranchDetailsPaymentSquareModel;
    authorizeNet?: BranchDetailsPaymentAuthorizeNetModel;
    payOnArrival?: BranchDetailsPaymentPayOnArrivalModel;
    payFast?: BranchDetailsPaymentPayFastModel;
    usaEpay: BranchDetailsPaymentUsaEpayModel;
    paystack: BranchDetailsPaymentPaystackModel;
    depositRate?: number;
    depositPercent?: boolean;
    depositOnly?: boolean;
}

export interface BranchDetailsPaymentPayFastModel {
    enabled?: boolean;
    redirectUrl?: boolean;
    sandbox?: boolean;
    onsite?: boolean;
}

export interface BranchDetailsPaymentPayOnArrivalModel {
    enabled?: boolean;
}

export interface BranchDetailsPaymentBraintreeModel {
    enabled?: boolean;
}

export interface BranchDetailsPaymentStripeModel {
    enabled?: boolean;
    publicKey?: string;
}

export interface BranchDetailsPaymentUsaEpayModel {
    enabled: boolean;
    publicKey: string;
    sandbox: boolean;
}

export interface BranchDetailsPaymentPaystackModel {
    enabled: boolean;
    publicKey: string;
    sandbox: boolean;
    currencyCode?: string;
}

export interface BranchDetailsPaymentSquareModel {
    enabled?: boolean;
    locationId?: string;
    applicationId?: string;
    sandbox?: boolean;
    currencyCode?: string;
    countryCode?: string;
}

export interface BranchDetailsPaymentAuthorizeNetModel {
    enabled?: boolean;
    loginId?: string;
    publicKey?: string;
    testMode?: boolean;
}

export interface BranchDetailsLocaleModel {
    locale?: string;
    localeAngular?: string;
    currencyCode?: string;
    currencyName?: string;
    playerOneName?: string;
    playerTwoName?: string;
    playerOneDescription?: string;
    playerTwoDescription?: string;
    allowPlayerTwo?: boolean;
    howOptions?: string[];
    timezone?: string;
    upgradeText?: string;
    additionalComments?: BranchDetailsLocaleAdditionalModel;
    additionalAction?: BranchDetailsLocaleAdditionalModel;
    additionalActionTwo?: BranchDetailsLocaleAdditionalModel;
    additionalTerms?: string[];
    heading?: string;
    subheading?: string;
    code?: string;
    voucherName?: string;
    noSlotsMessage?: string;
    payVia?: string;
    preferredPaymentText?: string;
}

export interface BranchDetailsLocaleAdditionalModel {
    show?: boolean;
    text?: string;
    required?: boolean;
    requiresAction?: boolean;
    requiresActionText?: string;
}

export interface BranchDetailsContactModel {
    address?: string;
    country?: string;
    phone?: string;
    email?: string;
    logo?: string;
    gmapUrl?: string;
}

export interface RoomModel {
    id?: number;
    name?: string;
    imageUrl?: string;
    description?: string;
    shortDescription?: string;
    times?: RoomTimeModel[];
    pricing?: RoomPriceModel[];
    players?: RoomPlayerModel;
    comingSoon?: boolean;
    virtual?: boolean;
    vr?: boolean;
    message?: string;
    gameOptions?: string[];
}

export interface RoomPlayerModel {
    minimumPlayerOne?: number;
    maximumPlayerOne?: number;
    minimumPlayerTwo?: number;
    maximumPlayerTwo?: number;
    maximumPlayers?: number;
    minimumPlayers?: number;
}

export interface RoomPriceModel {
    tier?: number;
    primary?: number;
    secondary?: number;
    upgrade?: number;
}

export interface RoomTimeModel {
    roomId?: number;
    id?: number;
    time?: string;
    formattedTime?: string;
    displayText?: string;
    available?: boolean;
}

export interface CreateBookingModel {
    bookingReference?: string;
    bookingDate?: string;
    time?: RoomTimeModel;
    room?: RoomModel;
    totalPlayers?: number;
    totalSecondaryPlayers?: number;
    products?: CreateBookingProductModel[];
    pricingTotals?: CreateBookingPricingTotalsModel;
    customerDetails?: CreateBookingCustomerDetailsModel;
    howDidYouFindUs?: string;
    howDidYouFindUsOther?: string;
    additionalAction?: CreateBookingCommentsModel;
    additionalActionTwo?: CreateBookingCommentsModel;
    additionalComments?: CreateBookingCommentsModel;
    additionalFields?: string[];
    coupon?: CreateBookingCouponModel;
    privateUpgrade?: boolean;
    payment?: CreateBookingPaymentModel;
    gameOption?: string;
}

export interface CreateBookingPaymentModel {
    name?: string;
    braintree?: CreateBookingPaymentBraintreeModel;
    stripe?: CreateBookingPaymentStripeModel;
    square?: CreateBookingPaymentSquareModel;
    vouchers?: CreateBookingPaymentVoucherModel;
    authorizeNet?: CreateBookingAuthorizeNetModel;
    payFast?: CreateBookingPayFastModel;
    usaEpay?: CreateBookingUsaEpayModel;
    paystack?: CreateBookingPaystackModel;
    deposit?: boolean;
}
export interface CreateBookingPaystackModel {
    nonce?: string;
}
export interface CreateBookingUsaEpayModel {
    nonce?: string;
}

export interface CreateBookingPayFastModel {
    nonce?: string;
}

export interface CreateBookingPaymentVoucherModel {
    voucher?: CreateBookingPaymentVoucherCodeModel[];
    total?: number;
}

export interface CreateBookingPaymentVoucherCodeModel {
    code?: string;
    amount?: number;
    type?: number;
    id?: string;
}

export interface CreateBookingPaymentBraintreeModel {
    nonce?: string;
}

export interface CreateBookingPaymentStripeModel {
    nonce?: string;
}

export interface CreateBookingPaymentSquareModel {
    nonce?: string;
}

export interface CreateBookingAuthorizeNetModel {
    nonce?: string;
}

export interface CreateBookingCouponModel {
    code?: string;
    success?: boolean;
    message?: string;
    clear?: boolean;
    rate?: number;
    percentage?: boolean;
    type?: CouponType;
}

export enum CouponType {
    PlayerOne = 1,
    PlayerTwo = 2,
    Total = 3
}

export interface CreateBookingCustomerDetailsModel {
    name?: string;
    phone?: string;
    email?: string;
}

export interface CreateBookingCommentsModel {
    comments?: string;
    actionRequired?: boolean;
}

export interface CreateBookingPricingTotalsModel {
    playerPrice?: number;
    subTotal?: number;
    tax?: number;
    price?: number;
    discount?: number;
    total?: number;
    privateUpgrade?: number;
    playerOnePrice?: number;
    playerTwoPrice?: number;
    totalVouchers?: number;
    balanceDue?: number;
    deposit?: number;
    playerOnePriceDiscountAmount?: number;
    playerTwoPriceDiscountAmount?: number;
}

export interface CreateBookingProductModel {
    id?: number;
    quantity?: number;
}

export interface ValidateVoucherModel {
    code?: string;
    bookingTotal?: number;
}
