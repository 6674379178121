import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './components/main/main.component';
import { ErrorComponent } from './components/error/error.component';


const appRoutes: Routes = [
    {
        path: ':branchId/:branchHash', component: MainComponent,
    },
    { path: 'error', component: ErrorComponent },
    { path: '**', redirectTo: 'error' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes, { useHash: false }
        )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
