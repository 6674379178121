import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SettingsService } from '../settings/settings.service';

@Injectable()
export class HttpClientService {
    private _apiUrl: string;

    constructor(private _http: HttpClient,
        private _settingsService: SettingsService) { }

    public async getObservable<T>(url: string, queryParams?: any): Promise<Observable<any>> {
        let params = this.createParams(queryParams);
        return this._http.get<T>(await this.getUrl(url), { params });
    }

    public async get<T>(url: string, queryParams?: any): Promise<T> {
        let params = this.createParams(queryParams);
        return this._http.get<T>(await this.getUrl(url), { params }).toPromise();
    }

    public async delete<T>(url: string, queryParams?: any): Promise<T> {
        let params = this.createParams(queryParams);
        return this._http.delete<T>(await this.getUrl(url), { params }).toPromise();
    }

    public async post<T>(url: string, data: any, queryParams?: any): Promise<T> {
        let params = this.createParams(queryParams);
        return this._http.post<T>(await this.getUrl(url), data, { params }).toPromise();
    }

    private createParams(queryParams: any): HttpParams {
        if (queryParams == null) {
            return null;
        }
        return new HttpParams({ fromObject: queryParams });
    }

    public async getUrl(path: string): Promise<string> {
        if (this._apiUrl == null) {
            this._apiUrl = this._settingsService.apiAddress;
        }
        return `${this._apiUrl}${path}`;
    }
}
