import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ModalService } from '../../modals/modal.service';
import {BookingService} from '../../services/booking/booking.service';


@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(
        private _modalService: ModalService,
                private _bookingService: BookingService) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.getRequestsWithHeaders(request);

        return next.handle(request).pipe(tap((event: any) => {
            if (event.body != null) {
                let body = event.body;
                if (body.tokenRenew != null) {
                    event.body = body.data;
                }
            }
        }, (error: HttpErrorResponse) => {
            if (error.status === 500 || error.status === 0) {
                this._modalService.openModal(this._modalService.modalTypes.serverError);
            } else if (error.status === 401) {
                this._modalService.openModal(this._modalService.modalTypes.timeout);
            }
        }));
    }

    private getRequestsWithHeaders(request: HttpRequest<any>) {
        request = request.clone(
            {
                setHeaders: {
                    'X-Branch': this._bookingService.getBranchId(),
                    'X-BranchHash': this._bookingService.getBranchHash(),
                    'X-SessionToken': this._bookingService.getSessionToken()
                }
            }
        );

        return request;
    }
}
