import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClarityModule } from '@clr/angular';

import { ModalService } from './modal.service';
import { ServerErrorModalComponent } from './server-error/server-error.modal.component';
import { TimeoutModalComponent } from './timeout/timeout.modal.component';

@NgModule({
    declarations: [
        TimeoutModalComponent,
        ServerErrorModalComponent
    ],
    imports: [
        BrowserModule,
        ClarityModule,
        BrowserAnimationsModule
    ],
    providers: [
        ModalService
    ],
    exports: [
        ServerErrorModalComponent,
        TimeoutModalComponent
    ]
})
export class ModalModule { }
