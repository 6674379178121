import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalService } from '../modal.service';

@Component({
    selector: 'ss-timeout',
    templateUrl: './timeout.modal.component.html'
})
export class TimeoutModalComponent implements OnInit, OnDestroy {
    private _msSub: Subscription;
    public showModal: boolean;

    constructor(private _modalService: ModalService,
        private _router: Router) { }

    public async ngOnInit(): Promise<void> {
        this._msSub = this._modalService.modal.subscribe(m => {
            if (m != null && m.type === this._modalService.modalTypes.timeout) {
                this.showModal = true;
            }
        });
    }

    public ngOnDestroy(): void {
        if (this._msSub != null) {
            this._msSub.unsubscribe();
        }
    }

    public closeModal() {
        window.location.reload();
    }
}
