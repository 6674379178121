import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {BookingService} from '../booking/booking.service';
import {BranchDetailsModel} from '../../components/main/main.component.model';
import {Subscription} from 'rxjs';
import {Angulartics2Facebook} from 'angulartics2/facebook';

declare var gtag: any;
declare var window: any;


@Injectable({
    providedIn: 'root'
})
export class FacebookAnalyticsService {
    private _branchSubscription: Subscription;
    public branchDetails: BranchDetailsModel;

    constructor(
        private _angularticsFacebook: Angulartics2Facebook,
        private _bookingService: BookingService,
        @Inject(DOCUMENT) document: any) {}

    public loadFacebookPixel(trackingId: string) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.text = `!function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '${trackingId}');
                        fbq('track', 'PageView');
                        fbq('track', 'InitiateCheckout');`;

        document.body.appendChild(script);
    }



    public triggerPurchase(amount: number, currencyCode: string = 'NZD') {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.text = `fbq('track', 'Purchase', {value: '${amount}', currency: '${currencyCode}'});`;

        document.body.appendChild(script);
    }
}