import 'moment/locale/en-nz';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {
    BranchDetailsModel,
    CouponType,
    CreateBookingCouponModel,
    CreateBookingModel,
    RoomModel,
    RoomPriceModel,
    RoomTimeModel,
    ValidateVoucherModel
} from '../../components/main/main.component.model';
import * as _ from 'lodash';
import {HttpClientService} from '../../core/http-client/http-client.service';
import {DatepickerDateCustomClasses} from "ngx-bootstrap/datepicker/models";
import {UUID} from "angular2-uuid";

@Injectable()
export class BookingService {
    private _bookingDetails: CreateBookingModel = {};
    private _branchDetails: BranchDetailsModel = {};
    private _sessionToken: string;

    private _branchId: number;
    private _branchHash: string;
    private _bookingReference: string;

    public bookingDetails: BehaviorSubject<CreateBookingModel>;
    public branchDetails: BehaviorSubject<BranchDetailsModel>;
    public iframeTarget: BehaviorSubject<string>;

    public bookingDate: any;


    constructor(private _http: HttpClientService) {
        this.bookingDetails = new BehaviorSubject<CreateBookingModel>(null);
        this.branchDetails = new BehaviorSubject<BranchDetailsModel>(null);
        this.iframeTarget = new BehaviorSubject<string>(null);

        this._sessionToken = UUID.UUID();

        this._bookingReference = null;

        this._bookingDetails = {
            totalPlayers: 0,
            totalSecondaryPlayers: 0,
            pricingTotals: {
                discount: 0
            },
            customerDetails: {},
            additionalComments: {},
            additionalAction: {},
            additionalActionTwo: {},
            coupon: {},
            payment: {
                braintree: {},
                stripe: {},
                authorizeNet: {},
                vouchers: {
                    voucher: []
                },
                square: {},
                payFast: {},
                usaEpay: {},
                paystack: {}
            },
        };

        this.setBranchId(0);
        this.setBranchHash('');
    }

    public setIframeTarget(target: string) {
        this.iframeTarget.next(target);
    }

    public setPaymentDeposit(deposit: boolean) {
        this._bookingDetails.payment.deposit = deposit;
    }

    public setCoupon(coupon: CreateBookingCouponModel) {
        this._bookingDetails.coupon = coupon;
        this.calculateBookingTotal();
    }

    public setUpgrade(upgrade: boolean) {
        this._bookingDetails.privateUpgrade = upgrade;
        this.calculateBookingTotal();
    }

    public setBranchId(branchId: number): any {
        this._branchId = branchId;
    }

    public getBranchId(): any {
        return this._branchId;
    }

    public setBranchHash(branchHash: string): any {
        this._branchHash = branchHash;
    }

    public getBranchHash(): any {
        return this._branchHash;
    }

    public getSessionToken(): string {
        return this._sessionToken;
    }

    public getBookingReference(): string {
        return this._bookingReference;
    }

    public setBookingReference(bookingReference: string) {
        this._bookingReference = bookingReference;
    }

    public getDate(): any {
        return this._bookingDetails.bookingDate;
    }
    public async getBranchDetails(): Promise<void> {
        this.branchDetails.next(this._branchDetails);
    }

    public async getBookingDetails(): Promise<void> {
        this.bookingDetails.next(this._bookingDetails);
    }

    public setTime(time: RoomTimeModel): void {
        this._bookingDetails.time = time;
    }

    public setRoom(room: RoomModel): void {
        if (room && this._bookingDetails.room) {
            if (room.id !== this._bookingDetails.room.id) {
                if (room.gameOptions.length > 0) {
                    this.setGameOption(room.gameOptions[0]);
                } else {
                    this.setGameOption('');
                }
            }
        } else if (room && !this._bookingDetails.room) {
            if (room.gameOptions.length > 0) {
                this.setGameOption(room.gameOptions[0]);
            } else {
                this.setGameOption('');
            }
        }

        this._bookingDetails.room = room;
    }

    public setDate(date: string): void {
        this._bookingDetails.bookingDate = date;
    }

    public setBranch(branch: BranchDetailsModel) {
        this._branchDetails = branch;
    }

    public setBraintreeNonce(nonce: string) {
        this._bookingDetails.payment.braintree.nonce = nonce;
    }

    public setAuthorizeNetNonce(nonce: string) {
        this._bookingDetails.payment.authorizeNet.nonce = nonce;
    }

    public setTotalPlayers(totalPlayers): void {
        this._bookingDetails.totalPlayers = totalPlayers;

        if (this._bookingDetails.totalSecondaryPlayers >= totalPlayers) {
            this.setSecondaryPlayers(0);
        }

        this.calculateBookingTotal();
    }

    public async getPayFastToken(email: string, amount: number): Promise<string> {
        const data = await this._http.get<any>(`payfast`, { email: email, amount: amount });
        return data.token;
    }

    public async getCalendarDateClasses(): Promise<DatepickerDateCustomClasses[]> {
        let result = await this._http.get<any>(`availabilityColours`);
        for (let r of result) {
            r.date = new Date(r.date);
        }
        return result;
    }


    public setSecondaryPlayers(secondaryPlayers): void {
        this._bookingDetails.totalSecondaryPlayers = secondaryPlayers;

        this.calculateBookingTotal();
    }

    public async validateVoucher(voucherCode: string): Promise<any>  {
        let voucherModel: ValidateVoucherModel = {
            code: voucherCode,
            bookingTotal: this._bookingDetails.pricingTotals.total || 0
        };

        let chargeAmount = this._bookingDetails.pricingTotals.total || 0;
        if (this._branchDetails.paymentProcessors.depositOnly) {
            chargeAmount = this._bookingDetails.pricingTotals.deposit;
        }
        let data = await this._http.post<any>(`voucher`, {
            code: voucherCode,
            bookingTotal: chargeAmount
        });

        if (data.success) {
            let amount = chargeAmount < data.value ? chargeAmount : data.value;
            this.addVoucherToBooking(data.code, amount, data.type, data.id);
        }

        return data;
    }

    public addVoucherToBooking(code: string, amount: number, type: number, id: string): void {
        // Check to make sure the voucher hasn't already been added
        if (!this._bookingDetails.payment.vouchers.voucher.find(x => x.code === code)) {
            this._bookingDetails.payment.vouchers.voucher.push({
                code: code,
                amount: amount,
                type: type,
                id: id
            });
        }

        this.calculateBookingTotal();
    }

    public removeVoucher(code: string): void {
        let voucher = this._bookingDetails.payment.vouchers.voucher.find(x => x.code === code);
        let index: number = this._bookingDetails.payment.vouchers.voucher.indexOf(voucher);

        if (index !== -1) {
            this._bookingDetails.payment.vouchers.voucher.splice(index, 1);
        }

        this.calculateBookingTotal();
    }

    public getPrice(primary: boolean): number {
        let currentTier: RoomPriceModel;
        let pricing = _.clone(this._bookingDetails.room.pricing);
        let price = 0;

        if (this._bookingDetails.room.pricing && this._bookingDetails.room.pricing.length) {
            if (primary) {
                let playerOneCount;
                if (this._branchDetails.calculateAllPlayers) {
                    playerOneCount = this._bookingDetails.totalPlayers;
                } else {
                    playerOneCount = this._bookingDetails.totalPlayers - this._bookingDetails.totalSecondaryPlayers;
                }

                for (let p of pricing) {
                    if (playerOneCount >= p.tier && p.primary ) {
                        currentTier = p;
                    }
                }

                if (currentTier) {
                    price = currentTier.primary;
                }
            } else {
                let playerTwoCount;
                if (this._branchDetails.calculateAllPlayers) {
                    playerTwoCount = this._bookingDetails.totalPlayers;
                } else {
                    playerTwoCount = this._bookingDetails.totalSecondaryPlayers;
                }

                for (let p of pricing) {
                    if (playerTwoCount >= p.tier && p.secondary ) {
                        currentTier = p;
                    }
                }

                if (currentTier) {
                    price = currentTier.secondary;
                }
            }

            if (this._branchDetails.taxInclusive && this._branchDetails.taxRate > 0) {
                price = price * (1 + (this._branchDetails.taxRate / 100));
            }

            return Math.round(price  * 100) / 100;
        }

        return 0.00;
    }

    public getUpgradePrice(): number {
        let currentTier: RoomPriceModel;
        let pricing = _.clone(this._bookingDetails.room.pricing);

        if (this._bookingDetails.room.pricing && this._bookingDetails.room.pricing.length) {
            for (let price of pricing) {
                if (this._bookingDetails.totalPlayers >= price.tier && price.upgrade ) {
                    currentTier = price;
                }
            }

            if (currentTier) {
                return currentTier.upgrade;
            }
        }

        return 0.00;
    }

    public setCustomerName(name: string): void {
        this._bookingDetails.customerDetails.name = name;
    }

    public setCustomerPhone(phone: string): void {
        this._bookingDetails.customerDetails.phone = phone;
    }

    public setCustomerEmail(email: string): void {
        this._bookingDetails.customerDetails.email = email;
    }

    public setAdditionalComments(comments: string): void {
        this._bookingDetails.additionalComments.comments = comments;
    }

    public setAdditionalCommentsAction(action: boolean): void {
        this._bookingDetails.additionalComments.actionRequired = action;
    }

    public setAdditionalActionAction(action: boolean): void {
        this._bookingDetails.additionalAction.actionRequired = action;
    }

    public setAdditionalActionTwoAction(action: boolean): void {
        this._bookingDetails.additionalActionTwo.actionRequired = action;
    }

    public setHowDidYouFindUs(how: string): void {
        this._bookingDetails.howDidYouFindUs = how;
    }

    public setGameOption(option: string): void {
        this._bookingDetails.gameOption = option;
    }

    public setHowDidYouFindUsOther(how: string): void {
        this._bookingDetails.howDidYouFindUsOther = how;
    }

    public calculateBookingTotal(): void {
        this._bookingDetails.pricingTotals.deposit = 0;
        this._bookingDetails.pricingTotals.playerPrice = 0;
        this._bookingDetails.pricingTotals.total = 0;
        this._bookingDetails.pricingTotals.tax = 0;

        let primaryPlayers = this._bookingDetails.totalPlayers;

        if (this._bookingDetails.totalSecondaryPlayers > 0) { primaryPlayers -=  this._bookingDetails.totalSecondaryPlayers; }

        this._bookingDetails.pricingTotals.playerOnePrice = this.getPrice(true);
        this._bookingDetails.pricingTotals.playerTwoPrice = this.getPrice(false);

        this._bookingDetails.pricingTotals.playerPrice += (this._bookingDetails.pricingTotals.playerOnePrice  * primaryPlayers) || 0;
        this._bookingDetails.pricingTotals.playerPrice += (this._bookingDetails.pricingTotals.playerTwoPrice  * this._bookingDetails.totalSecondaryPlayers) || 0;

        this._bookingDetails.pricingTotals.total += this._bookingDetails.pricingTotals.playerPrice;

        // add upgrade price
        let upgradePrice = this.getUpgradePrice();
        if (this._bookingDetails.privateUpgrade && upgradePrice) {
            this._bookingDetails.pricingTotals.privateUpgrade = upgradePrice;
            this._bookingDetails.pricingTotals.total += upgradePrice;
        } else {
            this._bookingDetails.pricingTotals.privateUpgrade = 0;
        }

        this._bookingDetails.pricingTotals.playerOnePriceDiscountAmount = 0;
        this._bookingDetails.pricingTotals.playerOnePriceDiscountAmount = 0;

        // calculate discount
        if (this._bookingDetails.coupon.rate > 0) {
            switch (this._bookingDetails.coupon.type) {
                case CouponType.PlayerOne:
                    const prePlayerOneDiscount = this._bookingDetails.pricingTotals.playerOnePrice;
                    if (this._bookingDetails.coupon.percentage) {
                        let discountPercent = this._bookingDetails.coupon.rate / 100;
                        this._bookingDetails.pricingTotals.playerOnePrice -= (this._bookingDetails.pricingTotals.playerOnePrice * discountPercent);
                    } else {
                        this._bookingDetails.pricingTotals.playerOnePrice -= this._bookingDetails.coupon.rate;
                    }
                    this._bookingDetails.pricingTotals.playerOnePriceDiscountAmount = prePlayerOneDiscount - this._bookingDetails.pricingTotals.playerOnePrice;
                    this._bookingDetails.pricingTotals.discount = (this._bookingDetails.pricingTotals.playerOnePriceDiscountAmount * (this._bookingDetails.totalPlayers - this._bookingDetails.totalSecondaryPlayers));
                break;

                case CouponType.PlayerTwo:
                    const prePlayerTwoDiscount = this._bookingDetails.pricingTotals.playerTwoPrice;
                    if (this._bookingDetails.coupon.percentage) {
                        let discountPercent = this._bookingDetails.coupon.rate / 100;
                        this._bookingDetails.pricingTotals.playerTwoPrice -= (this._bookingDetails.pricingTotals.playerTwoPrice * discountPercent);
                    } else {
                        this._bookingDetails.pricingTotals.playerTwoPrice -= this._bookingDetails.coupon.rate;
                    }
                    this._bookingDetails.pricingTotals.playerTwoPriceDiscountAmount = prePlayerTwoDiscount - this._bookingDetails.pricingTotals.playerTwoPrice;
                    this._bookingDetails.pricingTotals.discount = (this._bookingDetails.pricingTotals.playerTwoPriceDiscountAmount * this._bookingDetails.totalSecondaryPlayers);
                break;

                default:
                case CouponType.Total:
                    if (this._bookingDetails.coupon.percentage) {
                        let discountPercent = this._bookingDetails.coupon.rate / 100;
                        this._bookingDetails.pricingTotals.discount = this._bookingDetails.pricingTotals.playerPrice * discountPercent;
                    } else {
                        this._bookingDetails.pricingTotals.discount = this._bookingDetails.coupon.rate;
                    }
                break;
            }
        } else {
            this._bookingDetails.pricingTotals.discount = 0;
        }

        // apply discount
        this._bookingDetails.pricingTotals.total -= this._bookingDetails.pricingTotals.discount;

        this._bookingDetails.pricingTotals.subTotal = this._bookingDetails.pricingTotals.playerPrice;
        this._bookingDetails.pricingTotals.subTotal += this._bookingDetails.pricingTotals.privateUpgrade;

        // check if tax
        if (this._branchDetails.taxRate > 0 && this._bookingDetails.pricingTotals.total > 0) {
            if (this._branchDetails.taxInclusive) {
                this._bookingDetails.pricingTotals.tax = (this._bookingDetails.pricingTotals.total / (1 + (1 / (this._branchDetails.taxRate / 100))));
                this._bookingDetails.pricingTotals.subTotal -= this._bookingDetails.pricingTotals.tax;
            } else {
                this._bookingDetails.pricingTotals.tax = (this._bookingDetails.pricingTotals.total * (1 + (this._branchDetails.taxRate / 100))) - this._bookingDetails.pricingTotals.total;
                this._bookingDetails.pricingTotals.total += this._bookingDetails.pricingTotals.tax; // add tax onto the total
            }
        }


        if (this._bookingDetails.pricingTotals.total <= 0) {
            this._bookingDetails.pricingTotals.total = 0;
        } else {
            this._bookingDetails.pricingTotals.total = Math.round(this._bookingDetails.pricingTotals.total  * 100) / 100;
        }

        // Calculate and update the totals
        this._bookingDetails.payment.vouchers.total = 0;
        for (let voucher of this._bookingDetails.payment.vouchers.voucher) {
            this._bookingDetails.payment.vouchers.total += parseFloat(voucher.amount + '');
        }

        this._bookingDetails.pricingTotals.balanceDue = this._bookingDetails.pricingTotals.total;

        // If the vouchers exceed the booking total, just round it to match the booking total so balance is 0.
        if (this._bookingDetails.payment.vouchers.total >= this._bookingDetails.pricingTotals.total) {
            this._bookingDetails.pricingTotals.balanceDue = 0;
        } else {
            this._bookingDetails.pricingTotals.balanceDue -= this._bookingDetails.payment.vouchers.total;
        }

        // Calculate a deposit amount, if any.
        if (this._branchDetails.paymentProcessors.depositRate > 0) {
            if (this._branchDetails.paymentProcessors.depositPercent) {
                this._bookingDetails.pricingTotals.deposit = Math.round(this._bookingDetails.pricingTotals.total * (this._branchDetails.paymentProcessors.depositRate / 100) * 100) / 100;
            } else {
                this._bookingDetails.pricingTotals.deposit = this._branchDetails.paymentProcessors.depositRate;
            }
        }

        if (this._branchDetails.paymentProcessors.depositOnly === true) {
            if (this._bookingDetails.payment.vouchers.total === this._bookingDetails.pricingTotals.deposit) {
                this._bookingDetails.pricingTotals.balanceDue = 0;
            }
        }


        this.getBookingDetails();
    }
}
